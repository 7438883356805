// Colors
$transparent: rgba(255, 255, 255, 0) !default;
$white: #ffffff !default;
$light-white: #fcfcfc !default;
$black: #000000 !default;
$blue: #4D71FE;
$blue-lightest: #94AAFE;
$red: #F34A4A;
$lighter-red: #FEF6F6;
$yellow: #FFB446;
$light-yellow:#FFCB4A;
$light-green: #85D828;
$lighter-green: #F5FBF6;
$lightest-yellow: #FFF9EC;
$green: #55BD70;
$green-hover: #D4EDDB;
$red-hover: #FFE5E5;
$yellow-hover: #FFF2D2;
$gray: #afb3c1;
$light-gray: #D0D3DB;
$lighter-gray: #E3E4E9;
$lightest-gray: #F2F3F5;
$black-coral: #5e616d;
$anti-flash-white: #f3f3f3;
$nickel: #747272;

// colors
$primary: #4A74FF;
$primary-hover: #5678FF;
$secondary: #D7E0FF;
$secondary-hover: #4D71FE;
$secondary-pressed: #4565E4;
$secondary-disabled: #E3E4E9;
$secondary-hover-animate: #4D71FE 0.7;

$grey-text: #848998;
$unselected-light: #AFB3C1;
$unselected-disabled: #D0D3DB;
$disabled: #E3E4E9;
$scroll: #F2F3F5;
$scrollbar-dark: #d4d6dc;
$background: #EEF1FF;
$inactive: #848998;

$urgent: #F34A4A;
$urgent-border: #FFA5A5;
$urgent-hover: #DB4242;
$urgent-pressed: #F45B5B;
$urgent-light: #FF6969;
$no-error: #34B354;
$green-solid: #35B657;
$green-border: #99D7A9;
$green-hover: #2FA44E;
$green-pressed: #48BD67;

// text
$text-color-standard: #3D3F46 !default;
$text-color: $text-color-standard !default; //** Global text color

$body-bg: $white !default; //** Background color for `<body>`

$selection-text: $text-color !default; // Color of selected text
$selection-background: $body-bg !default; // Background of selected text

// fonts
$font-family-primary: europa, Helvetica, Arial, sans-serif;
$font-family-secondary: $font-family-primary;
$font-family-sans-serif: $font-family-primary;
$font-family-serif: $font-family-secondary;
$font-family-base: $font-family-primary;
