/* You can add global styles to this file, and also import other style files */
@import "scss/style";


// cookie consent styles
#cc-main {
    --cc-font-family: #{$font-family-primary};
    --cc-btn-primary-bg: #{$primary};
    --cc-btn-primary-hover-bg: #{$primary-hover};
    --cc-btn-primary-border-color: #{$primary};
    --cc-btn-primary-hover-border-color: #{$primary};
}
