.error-page-shown {

  .top-level-layout {

    .page {

      .container {
        display: flex;
        align-items: center;
        justify-content: center;

        .container-content {
          width: 100%;
        }
      }
    }
  }
}

.top-level-layout {
  position: relative;

  &.with-header {

    .top-level-layout-router-wrapper {
      padding-top: 86px;

      .page {
        padding-top: 30px;

        .container {
          min-height: calc(100vh - 146px);
        }
      }
    }
  }

  &.with-sidebar {

    .top-level-layout-router-wrapper {
      padding-left: 120px;
      padding-right: 120px;
    }
  }

  &.with-footer {
    
    .top-level-layout-router-wrapper {
      padding-bottom: 300px;
      
      .page {
        padding-bottom: 30px;

        .container {
          min-height: calc(100vh - 360px);
        }
      }
    }
  }

  &.with-header.with-footer {

    .page {
      padding-top: 0;
      padding-bottom: 0;

      .container {
        min-height: calc(100vh - 386px);
      }
    }
  }

  &.with-background-shape {

    .top-level-layout-router-wrapper {

      .page {
        min-width: 100%;

        .container {

          .container-shape {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
        
            &::after {
              display: inline-block;
              width: auto;
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background-color: #fff;
              border-radius: 20px;
              box-shadow: 10px 10px 30px #aeaec066;
              z-index: -1;
            }
          }
        }
      }
    }
  }

  .top-level-layout-router-wrapper {
    min-height: 100vh;
    padding-right: 40px;
    padding-left: 40px;

    .page {
      padding-top: 30px;
      padding-bottom: 30px;

      .container {
        min-height: calc(100vh - 236px);
        position: relative;

        .container-shape {
          display: none;
        }

        .container-content {
          position: relative;
          padding: 46px 40px 64px;
          z-index: 2;
        }
      }
    }
  }
}