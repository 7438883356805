@import "vars.scss";

.button {
  height: 48px;
  border-radius: 8px;
  font-size: 16px;
  line-height: 19px;
  font-family: $font-family-primary;
  padding: 14px 23px;
  font-weight: bold;
  box-shadow: 0 0 0 0.2rem transparent;
  white-space: nowrap;
  vertical-align: middle;
}

.button-primary {

  &:enabled {
    position: relative;
    box-shadow: 0 5px 10px 0 rgba(99, 99, 99, 0.2),
      0 10px 40px 0 rgba(74, 116, 255, 0.3);
    border: none;
    color: $white;
    z-index: 1;

    &::before,
    &::after {
      display: inline-block;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 8px;
    }

    &::before {
      background-image: linear-gradient($primary 0.11%, #4e6ffe 100%);
      z-index: -2;
    }

    &::after {
      background-color: $primary-hover;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease, visibility 0.3s ease, background-color 0.3s ease;
      z-index: -1;
    }

    &:hover {

      &::after {
        opacity: 1;
        visibility: visible;
      }
    }

    &:active {

      &::after {
        opacity: 1;
        visibility: visible;
      }
    }

    &:focus {
      outline: none;

      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &:disabled {
    color: $unselected-light;
    cursor: not-allowed;
    background-image: none;
    background-color: $disabled;
    border: none;
    box-shadow: none;

    &:hover {
      background-color: $disabled;
    }

    &.loading {
      position: relative;
      background-color: $transparent;
      overflow: hidden;
      cursor: progress;
      z-index: 1;

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $disabled;
        z-index: -2;
      }
    
      &::after {
        animation-name: fillToRight;
        animation-iteration-count: infinite;
        animation-duration: 1.8s;
        animation-timing-function: ease-in-out;
        content: '';
        display: inline-block;
        width: 0;
        position: absolute;
        top: 0;
        right: auto;
        bottom: 0;
        left: -10px;
        background-color: #d2d6db;
        z-index: -1;
      }
    }
  }

  &--medium {
    height: 42px;
    padding: 12px 15px;
    line-height: 16px;
    font-size: 14px;
  }

  &.no-shadow {
    box-shadow: none;
  }
}

a.button-primary {
  position: relative;
  box-shadow: 0 5px 10px 0 rgba(99, 99, 99, 0.2),
    0 10px 40px 0 rgba(74, 116, 255, 0.3);
  border: none;
  color: $white;
  text-decoration: none;
  z-index: 1;

  &::before,
  &::after {
    display: inline-block;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 8px;
  }

  &::before {
    background-image: linear-gradient($primary 0.11%, #4e6ffe 100%);
    z-index: -2;
  }

  &::after {
    background-color: $primary-hover;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease, background-color 0.3s ease;
    z-index: -1;
  }

  &:hover {

    &::after {
      opacity: 1;
      visibility: visible;
    }
  }

  &:active {

    &::after {
      opacity: 1;
      visibility: visible;
    }
  }

  &:focus {
    outline: none;

    &::after {
      opacity: 1;
      visibility: visible;
    }
  }

  &.no-shadow {
    box-shadow: none;
  }
}

.button-secondary {
  transition: border-color 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;

  &:enabled {
    background-color: $white;
    border: 1px solid $secondary;
    color: $secondary-hover;

    &:hover {
      background: $white;
      border-color: $secondary-hover;
      color: $secondary-hover;
    }

    &:active {
      border-color: $secondary-pressed;
      background-color: $white;
      color: $secondary-hover;
    }

    &:focus {
      outline: none;
      border-color: $secondary-pressed;
      box-shadow: 0 0 0 0.2rem $white;
    }

    :not(:focus) {
      background-color: $white;
    }
  }

  &:disabled {
    background-image: none;
    background-color: $white;
    border: 1px solid $secondary-disabled;
    color: $unselected-light;
    cursor: not-allowed;
    box-shadow: none;
  }

  &--smaller {
    height: 36px;
    padding: 8px 10px;
    font-size: 15px;
    display: flex;
  }

  &--red {

    &:enabled {
      border: 1px solid $urgent-border;
      color: $urgent;

      .material-icons {
        padding-top: 2px;
        padding-right: 6px;
        font-size: 14px;
      }

      &:hover {
        border-color: $urgent-hover;
        color: $urgent-hover;
      }

      &:active {
        border-color: $urgent-pressed;
        color: $urgent-hover;
      }

      &:focus {
        border-color: $urgent-pressed;
      }

      &.marked {
        color: $white;
        background-color: $urgent;
        border-color: $urgent-border;
      }
    }

    &--full {
      
      &:enabled {
        border: 1px solid $urgent-border;
        color: $urgent;
        transition: color .3s ease, border-color .3s ease, background-color .3s ease;

        &:hover,
        &:focus {
          color: $white;
          background-color: $urgent;
          border-color: $urgent-border;
        }

        &:active {
          color: $white;
          background-color: darken($urgent, 5%);
          border-color: darken($urgent-border, 5%);
        }
      }
    }

    &:disabled {

      .material-icons {
        padding-top: 2px;
        padding-right: 6px;
        font-size: 14px;
      }
    }
  }

  &--green {

    &:enabled {
      border: 1px solid $green-border;
      color: $green-solid;

      .material-icons {
        padding-top: 2px;
        padding-right: 6px;
        font-size: 14px;
      }

      &:hover {
        border-color: $green-hover;
        color: $green-hover;
      }

      &:active {
        border-color: $green-pressed;
        color: $green-hover;
      }

      &:focus {
        border-color: $green-pressed;
      }

      &.marked {
        color: $white;
        background-color: $green-solid;
        border-color: $green-border;
      }
    }

    &:disabled {

      .material-icons {
        padding-top: 2px;
        padding-right: 6px;
        font-size: 14px;
      }
    }
  }
}

.button-delete {
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  padding: 15px 23px 15px 20px;
  font-weight: normal;

  &:enabled {
    background-color: $unselected-light;
    color: $white;
    display: flex;
    border: none;

    .material-icons {
      font-size: 19px;
      padding-right: 10px;
      padding-bottom: 2px;
    }

    &:hover {
      border: none;
      background-color: $urgent;
      box-shadow: 0 5px 10px 0 rgba(99, 99, 99, 0.2),
        0 10px 40px 0 rgba(243, 74, 74, 0.3);
    }

    &:active {
      background-color: $urgent-light;
      border: none;
      box-shadow: 0 5px 10px 0 rgba(99, 99, 99, 0.2),
        0 10px 40px 0 rgba(243, 74, 74, 0.3);
    }

    &:visited {
      border: none;
    }
  }

  &:disabled {
    border: none;
    cursor: not-allowed;
    background-color: $disabled;
    color: $unselected-light;
    display: flex;

    .material-icons {
      font-size: 19px;
      padding-right: 10px;
      padding-bottom: 2px;
    }
  }
}

.button-w-icon {
  padding: 14px 21px 15px 20px;
  border: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:enabled {
    background-color: $white;
    border: 1px solid $secondary;
    color: $secondary-hover;
    display: flex;

    .material-icons {
      font-size: 16px;
      padding-right: 15px;
      padding-top: 2px;
    }

    &:hover {
      background: $white;
      border-color: $secondary-hover;
      color: $secondary-hover;
    }

    &:active {
      border-color: $secondary-pressed;
      background-color: $white;
      color: $secondary-hover;
    }

    &:focus {
      outline: none;
      border-color: $secondary-pressed;
      box-shadow: 0 0 0 0.2rem $white;
    }

    :not(:focus) {
      background-color: $white;
    }
  }

  &:disabled {
    background-color: $white;
    border: 1px solid $secondary-disabled;
    color: $unselected-light;
    background-image: none;
    cursor: not-allowed;
    display: flex;
    border: 1px solid $disabled;

    .material-icons {
      font-size: 16px;
      padding-right: 15px;
      padding-top: 2px;
    }
  }

  &--smaller {
    height: 36px;
    padding: 7px 10px;
    font-size: 14px;

    &:enabled {

      span {
        padding-top: 2px;
        padding-right: 8px;
        font-size: 16px;
      }
    }

    &:disabled {
      
      span {
        padding-top: 2px;
        padding-right: 8px;
        font-size: 16px;
      }
    }
  }
}

.button-light {
  height: 36px;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 8px;
  line-height: 19px;
  font-family: $font-family-primary;
  font-weight: bold;
  box-shadow: 0 0 0 0.2rem transparent;
  white-space: nowrap;
  vertical-align: middle;
  color: $text-color-standard;
  transition: border .5s ease, background-color .5s ease;

  &--red {

    &:enabled {
      border: 1px solid $lighter-red;
      background-color: $lighter-red;

      &:hover {
        border-color: lighten($urgent-light,10%);
        background-color: lighten($urgent-light, 10%);
      }

      &:active {
        border-color: $urgent-light;
        background-color: $urgent-light;
      }

      &:focus {
        background-color: $urgent-light;
        border-color: $urgent-light;
      }

      &.highlight {
        background-color: $urgent-pressed;
        border-color: $urgent-pressed;
      }
    }
  }

  &--green {

    &:enabled {
      border: 1px solid $lighter-green;
      background-color: $lighter-green;

      &:hover {
        border-color: lighten($green-border, 10%);
        background-color: lighten($green-border, 10%);
      }

      &:active {
        border-color: $green-border;
        background-color: $green-border;
      }

      &:focus {
        border-color: $green-border;
        background-color: $green-border;
      }

      &.highlight {
        background-color: $green-pressed;
        border-color: $green-pressed;
      }
    }
  }

  &--yellow {

    &:enabled {
      border: 1px solid $lightest-yellow;
      background-color: $lightest-yellow;

      &:hover {
        border-color: lighten($light-yellow, 10%);
        background-color: lighten($light-yellow, 10%);
      }

      &:active {
        border-color: $light-yellow;
        background-color: $light-yellow;
      }

      &:focus {
        border-color: $light-yellow;
        background-color: $light-yellow;
      }

      &.highlight {
        background-color: $yellow;
        border-color: $yellow;
      }
    }
  }

  &--gray {

    &:enabled {
      border: 1px solid $lightest-gray;
      background-color: $lightest-gray;

      &:hover {
        border-color: lighten($gray, 20%);
        background-color: lighten($gray, 20%);
      }

      &:active {
        border-color: lighten($gray, 10%);
        background-color: lighten($gray, 10%);
      }

      &:focus {
        border-color: lighten($gray, 10%);
        background-color: lighten($gray, 10%);
      }

      &.highlight {
        border-color: lighten($gray, 10%);
        background-color: lighten($gray, 10%);
      }
    }
  }
}

.p-disabled:disabled {
  opacity: 1;
  cursor: not-allowed;
}

.button-unmask {
  padding: 0;
  position: absolute;
  top: 12px;
  right: 0px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  background: $transparent;

  &.hide-password {

    .unmask::before {
      display: inline-block;
      content: '';
      position: absolute;
      left: 12px;
      top: 12px;
      width: 2px;
      height: 25px;
      background-color: $gray;
      transform: translate(-50%, -50%) rotate(45deg);
      transform-origin: 0% 50%;
      z-index: 1;
    }
  }

  .unmask {
    display: inline-block;
    position: relative;
    line-height: normal;
  }

  .unmask,
  svg {
    pointer-events: none;
  }
}
